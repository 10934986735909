import React from 'react';
import styled from 'styled-components';
import { device} from '../theme';
import { easing } from '../animations';

const SVG = styled.svg`
    position: relative;
    height: auto;
    top: 4px;
    margin: 0 auto;
    width: 100%;
    left: -4.5%;
    transition: ${easing.theme};

    @media ${device.mobileL} {
        width: 116%;
        height: auto;
        top: -2px;
        left: -8.25%;
    }

    @media ${device.tablet} {
        width: 117%;
    }

    @media ${device.laptop} {
        width: 805px;
    }
`;

const Laptop = (props) => {
    return (
        <SVG viewBox="0 0 755 442" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="1.82892993e-15%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
                    <stop stopColor="#686868" offset="7%"></stop>
                    <stop stopColor="#4F4F4F" offset="7%"></stop>
                    <stop stopColor="#313131" offset="45%"></stop>
                    <stop stopColor="#4A4A4A" offset="93%"></stop>
                    <stop stopColor="#686868" offset="94%"></stop>
                </linearGradient>
                <linearGradient x1="50.0041015%" y1="19.6796339%" x2="50.0041015%" y2="115.789474%" id="linearGradient-2">
                    <stop stopColor="#5C5C5C" offset="0%"></stop>
                    <stop stopColor="#A6A6A6" offset="49%"></stop>
                    <stop stopColor="#5C5C5C" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="49.999337%" y1="23.8317757%" x2="49.999337%" y2="109.11215%" id="linearGradient-3">
                    <stop stopColor="#A6A6A6" offset="0%"></stop>
                    <stop stopColor="#5C5C5C" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50.3409091%" y1="52.0532025%" x2="49.0909091%" y2="43.0464876%" id="linearGradient-4">
                    <stop stopColor="#353535" offset="0%"></stop>
                    <stop stopColor="#171617" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="50.2272727%" y1="52.0258264%" x2="49.0909091%" y2="43.0464876%" id="linearGradient-5">
                    <stop stopColor="#353535" offset="0%"></stop>
                    <stop stopColor="#171617" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="0%" y1="50.6060606%" x2="100%" y2="50.6060606%" id="linearGradient-6">
                    <stop stopColor="#343434" offset="0%"></stop>
                    <stop stopColor="#FFFFFF" offset="0%"></stop>
                    <stop stopColor="#4A4A4A" offset="1%"></stop>
                    <stop stopColor="#505050" offset="3%"></stop>
                    <stop stopColor="#C3C3C3" offset="13%"></stop>
                    <stop stopColor="#C0C0C0" offset="94%"></stop>
                    <stop stopColor="#484848" offset="98%"></stop>
                    <stop stopColor="#FFFFFF" offset="100%"></stop>
                    <stop stopColor="#343434" offset="100%"></stop>
                </linearGradient>
                <linearGradient x1="-0.103446904%" y1="49.0835031%" x2="100.177716%" y2="49.0835031%" id="linearGradient-7">
                    <stop stopColor="#464646" offset="0%"></stop>
                    <stop stopColor="#737373" offset="1%"></stop>
                    <stop stopColor="#444444" offset="2%"></stop>
                    <stop stopColor="#404040" offset="98%"></stop>
                    <stop stopColor="#6C6C6C" offset="100%"></stop>
                    <stop stopColor="#444444" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Laptop">
                    <rect id="Rectangle" fill="#1B1B18" fillRule="nonzero" x="51.98" y="399.99" width="650.63" height="16.41"></rect>
                    <path d="M692.73,0 L61.82,0 C56.3962787,0.0274651495 52.0109209,4.42622012 52,9.85 L52,400.49 C52.0164725,405.917644 56.4123558,410.313528 61.84,410.33 L692.73,410.33 C698.161549,410.319019 702.563502,405.921535 702.58,400.49 L702.58,9.85 C702.563527,4.41683301 698.163167,0.0164725753 692.73,0 Z M699.84,399.94 C699.829009,404.16594 696.40594,407.589009 692.18,407.6 L62.37,407.6 C58.1463295,407.583555 54.7264454,404.163671 54.71,399.94 L54.71,10.4 C54.7209908,6.17405973 58.1440597,2.7509908 62.37,2.74 L692.18,2.74 C696.408217,2.74550908 699.84,6.17178286 699.84,10.4 L699.84,399.94 Z" id="Shape" fill="#4D4D4D" fillRule="nonzero"></path>
                    <rect id="Rectangle" x="54.71" y="2.74" width="645.13" height="404.86" rx="7.66"></rect>
                    <path d="M699.84,399.94 C699.829009,404.16594 696.40594,407.589009 692.18,407.6 L62.37,407.6 C58.1463295,407.583555 54.7264454,404.163671 54.71,399.94 L54.71,10.4 C54.7209908,6.17405973 58.1440597,2.7509908 62.37,2.74 L692.18,2.74 C696.408217,2.74550908 699.84,6.17178286 699.84,10.4 L699.84,399.94 Z M692.73,0 L61.82,0 C56.3962787,0.0274651495 52.0109209,4.42622012 52,9.85 L52,400.49 C52.0164725,405.917644 56.4123558,410.313528 61.84,410.33 L692.73,410.33 C698.161549,410.319019 702.563502,405.921535 702.58,400.49 L702.58,9.85 C702.563527,4.41683301 698.163167,0.0164725753 692.73,0 Z" id="Shape" fill="#212121" fillRule="nonzero"></path>
                    <rect id="Rectangle" x="54.49" y="2.52" width="645.57" height="405.3" rx="7.88"></rect>
                    <path d="M700.06,399.94 C700.049008,404.287443 696.527443,407.809008 692.18,407.82 L62.37,407.82 C58.0225575,407.809008 54.5009923,404.287443 54.49,399.94 L54.49,10.4 C54.5009923,6.05255748 58.0225575,2.53099232 62.37,2.52 L692.18,2.52 C696.527443,2.53099232 700.049008,6.05255748 700.06,10.4 L700.06,399.94 Z M692.73,0.22 L61.82,0.22 C56.5060617,0.231001978 52.201002,4.5360617 52.19,9.85 L52.19,400.49 C52.201002,405.803938 56.5060617,410.108998 61.82,410.12 L692.73,410.12 C698.043938,410.108998 702.348998,405.803938 702.36,400.49 L702.36,9.85 C702.348998,4.5360617 698.043938,0.231001978 692.73,0.22 Z" id="Shape" fill="#29292A" fillRule="nonzero"></path>
                    <rect id="Rectangle" x="54.27" y="2.3" width="646.01" height="405.74" rx="8.1"></rect>
                    <path d="M700.28,399.94 C700.269006,404.408945 696.648945,408.029006 692.18,408.04 L62.37,408.04 C57.9010552,408.029006 54.2809938,404.408945 54.27,399.94 L54.27,10.4 C54.2809938,5.93105521 57.9010552,2.31099376 62.37,2.3 L692.18,2.3 C696.648945,2.31099376 700.269006,5.93105521 700.28,10.4 L700.28,399.94 Z M692.73,0.44 L61.82,0.44 C56.6275641,0.451000961 52.421001,4.65756408 52.41,9.85 L52.41,400.49 C52.421001,405.682436 56.6275641,409.888999 61.82,409.9 L692.73,409.9 C697.922436,409.888999 702.128999,405.682436 702.14,400.49 L702.14,9.85 C702.128999,4.65756408 697.922436,0.451000961 692.73,0.44 Z" id="Shape" fill="#323133" fillRule="nonzero"></path>
                    <rect id="Rectangle" x="54.06" y="2.08" width="646.45" height="406.18" rx="8.32"></rect>
                    <path d="M700.5,399.94 C700.5,404.531103 696.771107,408.25 692.18,408.25 L62.37,408.25 C57.782798,408.24449 54.06,404.527202 54.06,399.94 L54.06,10.4 C54.06,5.80889329 57.7788966,2.08551816 62.37,2.08 L692.18,2.08 C696.772725,2.08551017 700.5,5.80727521 700.5,10.4 L700.5,399.94 Z M692.73,0.66 L61.82,0.66 C56.7467878,0.665511371 52.63,4.77678779 52.63,9.85 L52.63,400.49 C52.6409999,405.560934 56.7490664,409.669 61.82,409.68 L692.73,409.68 C697.803212,409.674489 701.92,405.563212 701.92,400.49 L701.92,9.85 C701.92,4.77678779 697.803212,0.665511371 692.73,0.66 Z" id="Shape" fill="#3A393B" fillRule="nonzero"></path>
                    <path d="M62.37,1.86 L692.18,1.86 C696.890989,1.86 700.71,5.67901108 700.71,10.39 L700.71,399.94 C700.71,404.650989 696.890989,408.47 692.18,408.47 L62.37,408.47 C57.6590111,408.47 53.84,404.650989 53.84,399.94 L53.84,10.4 C53.84,5.68739072 57.657394,1.86551828 62.37,1.86 Z" id="Path"></path>
                    <path d="M700.72,399.94 C700.708985,404.650322 696.890331,408.464504 692.18,408.47 L62.37,408.47 C57.6612955,408.46449 53.84,404.648705 53.84,399.94 L53.84,10.4 C53.84,5.68966869 57.6596784,1.87101512 62.37,1.86 L692.18,1.86 C696.894227,1.8655105 700.72,5.68577265 700.72,10.4 L700.72,399.94 Z M692.73,0.88 L61.82,0.88 C56.8494373,0.88 52.82,4.90943725 52.82,9.88 L52.82,400.49 C52.82,405.460563 56.8494373,409.49 61.82,409.49 L692.73,409.49 C697.700563,409.49 701.73,405.460563 701.73,400.49 L701.73,9.85 C701.71347,4.891157 697.688871,0.88 692.73,0.88 Z" id="Shape" fill="#434144" fillRule="nonzero"></path>
                    <path d="M62.37,1.64 L692.18,1.64 C697.012492,1.64 700.93,5.55750844 700.93,10.39 L700.93,399.94 C700.93,404.772492 697.012492,408.69 692.18,408.69 L62.37,408.69 C57.5375084,408.69 53.62,404.772492 53.62,399.94 L53.62,10.4 C53.62,5.56588815 57.5358913,1.64551839 62.37,1.64 Z" id="Path"></path>
                    <path d="M700.94,399.94 C700.94,404.774109 697.014112,408.69 692.18,408.69 L62.37,408.69 C57.5375084,408.69 53.62,404.772492 53.62,399.94 L53.62,10.4 C53.62,5.56588815 57.5358913,1.64551839 62.37,1.64 L692.18,1.64 C697.01573,1.64551081 700.94,5.56427008 700.94,10.4 L700.94,399.94 Z M692.73,1.09 L61.82,1.09 C56.9881758,1.10101589 53.07,5.01816635 53.07,9.85 L53.07,400.49 C53.0809976,405.317929 56.9920711,409.229002 61.82,409.24 L692.73,409.24 C697.561834,409.234503 701.478984,405.321824 701.49,400.49 L701.49,9.85 C701.49,5.01427008 697.56573,1.09551081 692.73,1.09 Z" id="Shape" fill="#4B494D" fillRule="nonzero"></path>
                    <path d="M55.86,409.63 C54.4111995,409.649647 52.9650448,409.759862 51.53,409.96 L2.7,417.45 C1.93986056,417.571266 1.19537791,417.775831 0.48,418.06 L0.1,418.53 L0.56,421.39 L754,421.39 L754.34,418.61 L754.03,418.05 C753.338567,417.748708 752.608,417.546708 751.86,417.45 L703,410 C701.561642,409.799498 700.112138,409.689282 698.66,409.67 L55.86,409.63 Z" id="Path" fill="url(#linearGradient-1)"></path>
                    <path d="M72.32,436.24 C72.32,436.24 80.17,438 82.46,439.34 C84.7957218,440.752629 87.4440897,441.566719 90.17,441.71 L664,441.71 C666.726128,441.56795 669.374789,440.75377 671.71,439.34 C674,438 681.85,436.24 681.85,436.24 L72.32,436.24 Z" id="Path" fill="#2E2E2E"></path>
                    <path d="M72.32,436.24 C72.32,436.24 80.17,437.65 82.46,438.72 C84.8763418,439.863225 87.498881,440.506104 90.17,440.61 L664,440.61 C666.671235,440.506928 669.293937,439.864009 671.71,438.72 C674,437.65 681.85,436.24 681.85,436.24 L72.32,436.24 Z" id="Path" fill="url(#linearGradient-2)"></path>
                    <path d="M752.73,424.92 C752.36,424.92 722.93,425.81 700,426.06 L54.9,426.06 C31.97,425.81 2.55,424.92 2.17,424.92 C1.67663791,424.933084 1.20870236,425.141711 0.869223905,425.499945 C0.52974545,425.858179 0.34655945,426.336647 0.359309327,426.83 C0.359309327,427.88 1.27,428.28 2.17,428.73 C6.08,430.73 26.33,438.25 51.37,438.44 L703.53,438.44 C728.53,438.25 748.83,430.69 752.73,428.73 C753.64,428.28 754.550451,427.88 754.550451,426.83 C754.572379,425.800946 753.758936,424.947278 752.73,424.92 L752.73,424.92 Z" id="Path" fill="#ACACAC"></path>
                    <path d="M752.73,424.92 C752.36,424.92 722.93,425.76 700,426 L54.9,426 C31.97,425.76 2.55,424.92 2.17,424.92 C1.1703646,424.92 0.36,425.730365 0.36,426.73 C0.36,427.73 1.27,428.11 2.17,428.54 C6.08,430.4 26.33,437.54 51.37,437.76 L703.53,437.76 C728.53,437.57 748.83,430.4 752.73,428.54 C753.64,428.11 754.55,427.73 754.55,426.73 C754.55,425.728749 753.731266,424.92 752.73,424.92 L752.73,424.92 Z" id="Path" fill="url(#linearGradient-3)"></path>
                    <path d="M233.71,434.32 C233.71,435 231.66,435.55 229.13,435.55 C226.6,435.55 224.55,435 224.55,434.32 C224.55,433.64 226.55,433.09 229.13,433.09 C231.71,433.09 233.71,433.64 233.71,434.32 Z" id="Path" fill="#171617"></path>
                    <path d="M233.53,434.49 C233.53,435.08 231.53,435.55 229.13,435.55 C226.73,435.55 224.73,435.08 224.73,434.49 C224.73,433.9 226.73,433.43 229.13,433.43 C231.53,433.43 233.53,433.91 233.53,434.49 Z" id="Path" fill="url(#linearGradient-4)"></path>
                    <path d="M231.4,434 C231.4,434.3 230.4,434.55 229.12,434.55 C227.84,434.55 226.84,434.3 226.84,434 C226.84,433.7 227.84,433.45 229.12,433.45 C230.4,433.45 231.4,433.68 231.4,434 Z" id="Path" fill="#171617"></path>
                    <path d="M530.55,434.3 C530.55,434.98 528.55,435.53 525.97,435.53 C523.39,435.53 521.39,434.98 521.39,434.3 C521.39,433.62 523.44,433.07 525.97,433.07 C528.5,433.07 530.55,433.62 530.55,434.3 Z" id="Path" fill="#171617"></path>
                    <path d="M530.37,434.47 C530.37,435.05 528.37,435.53 525.97,435.53 C523.57,435.53 521.57,435.05 521.57,434.47 C521.57,433.89 523.57,433.41 525.97,433.41 C528.37,433.41 530.37,433.88 530.37,434.47 Z" id="Path" fill="url(#linearGradient-5)"></path>
                    <path d="M528.24,434 C528.24,434.3 527.24,434.54 525.96,434.54 C524.68,434.54 523.68,434.3 523.68,434 C523.68,433.7 524.68,433.45 525.96,433.45 C527.24,433.45 528.24,433.66 528.24,434 Z" id="Path" fill="#171617"></path>
                    <rect id="Rectangle" fill="#7A7A7A" fillRule="nonzero" x="0.56" y="421.57" width="753.44" height="3.81"></rect>
                    <path d="M1.09,423.93 L753.46,423.93 C754.06199,423.93 754.55,424.41801 754.55,425.02 L754.55,426.65 C754.55,427.25199 754.06199,427.74 753.46,427.74 L1.1,427.74 C0.492486775,427.74 0,427.247513 0,426.64 L0,425 C0.0109039478,424.405834 0.495734397,423.9299 1.09,423.93 L1.09,423.93 Z" id="Path" fill="#656565" fillRule="nonzero"></path>
                    <path d="M753.46,427.47 L1.09,427.47 C0.637126505,427.47 0.27,427.102873 0.27,426.65 L0.27,425 C0.27,424.545481 0.635513661,424.175476 1.09,424.17 L753.46,424.17 C753.914486,424.175476 754.28,424.545481 754.28,425 L754.28,426.62 C754.28,426.837478 754.193607,427.046048 754.039828,427.199828 C753.886048,427.353607 753.677478,427.44 753.46,427.44 L753.46,427.47 Z" id="Path" fill="url(#linearGradient-6)"></path>
                    <path d="M360.59,421.57 L360.59,424.63 C360.595475,425.837228 361.572772,426.814525 362.78,426.82 L392.12,426.82 C393.327228,426.814525 394.304525,425.837228 394.31,424.63 L394.31,421.57 L360.59,421.57 Z" id="Path" fill="#E6E6E6"></path>
                    <path d="M361.139969,421.57 L361.139969,424.63 C361.137318,425.065772 361.309242,425.484472 361.617385,425.792615 C361.925528,426.100758 362.344228,426.272682 362.78,426.270031 L392.12,426.270031 C393.025747,426.270031 393.76,425.535747 393.76,424.63 L393.76,421.57 L361.139969,421.57 Z" id="Path" fill="#616161"></path>
                    <path d="M361.68,421.57 L361.68,424.63 C361.68,425.237513 362.172487,425.73 362.78,425.73 L392.12,425.73 C392.410827,425.73 392.689599,425.613802 392.894304,425.407219 C393.099008,425.200636 393.212668,424.920815 393.210046,424.63 L393.210046,421.57 L361.68,421.57 Z" id="Path" fill="#C6C6C6"></path>
                    <path d="M1.1,417.87 L753.46,417.87 C754.06199,417.87 754.55,418.35801 754.55,418.96 L754.55,422.23 C754.55,422.83199 754.06199,423.32 753.46,423.32 L1.1,423.32 C0.492486775,423.32 0,422.827513 0,422.22 L0,419 C0,418.392487 0.492486775,417.9 1.1,417.9 L1.1,417.87 Z" id="Path" fill="#1B1B1B" fillRule="nonzero"></path>
                    <path d="M753.46,423.05 L1.09,423.05 C0.637126505,423.05 0.27,422.682873 0.27,422.23 L0.27,419 C0.27,418.547127 0.637126505,418.18 1.09,418.18 L753.46,418.18 C753.677478,418.18 753.886048,418.266393 754.039828,418.420172 C754.193607,418.573952 754.28,418.782522 754.28,419 L754.28,422.27 C754.28,422.487478 754.193607,422.696048 754.039828,422.849828 C753.886048,423.003607 753.677478,423.09 753.46,423.09 L753.46,423.05 Z" id="Path" fill="url(#linearGradient-7)"></path>
                    <path d="M692.18,2.74 L62.37,2.74 C58.1440597,2.7509908 54.7209908,6.17405973 54.71,10.4 L54.71,399.94 C54.7264454,404.163671 58.1463295,407.583555 62.37,407.6 L692.18,407.6 C696.40594,407.589009 699.829009,404.16594 699.84,399.94 L699.84,10.4 C699.84,6.17178286 696.408217,2.74550908 692.18,2.74 Z M694.71,367.57 L57.31,367.57 L57.31,10.84 L694.71,10.84 L694.71,367.57 Z" id="Shape" fill="#1B1B18" fillRule="nonzero"></path>
                </g>
            </g>
        </SVG>
    )
}

export default Laptop;
