import React, { Fragment } from 'react';
import styled from 'styled-components';
// Components
import UniversalLink from '../UniversalLink';
import FluidImage from '../FluidImage';
// Theme
import "../layout.css"
import { device } from '../../global/theme';

const StyledFluidImage = styled(FluidImage)`
    width: 290px;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

const SliderItemLink = styled(UniversalLink)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const SliderItemContainer = styled.div`
  position: relative;
  width: 710px;
  height: auto;
`;

const SliderItem = ({ item }) => {
  console.log(item);
    return (
      <Fragment>
        <SliderItemContainer>
        <SliderItemLink to={item.link.url} />
          <StyledFluidImage image={item.image} />
        </SliderItemContainer>
      </Fragment>
    );
  };
  
export default SliderItem;