import React from 'react';
import styled from 'styled-components'
// Components
import FluidImage from '../../components/FluidImage';
// Atoms
import { Button } from '../../atoms/button/Button';
import { Icon } from '../../atoms/icon/Icon';
// Theme
import { easing } from '../../global/animations';
import { Heading } from '../../atoms/typography/headings';
//import { color } from '../../global/theme';
// import { Heading } from '../../atoms/typography/headings'
import { blogURI } from "../../../globals"


const Overlay = styled.div`
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    right: 0;
    top: 0;
    background: ${({ theme }) => theme.tertiaryPrimary};
    border-radius: 50%;
    z-index: 9;
    transition: ${easing.theme};
`;

const StyledIcon = styled(Icon)`
    position: absolute;
    width: 30px;
    height: 30px;
    left: 25%;
    bottom: 25%;
    z-index: 9;
    fill:  ${({ theme }) => theme.tertiaryPrimary};
`;

const PostTextContainer = styled.div`
    position: absolute;
    width: 97%;
    height: auto;
    background: ${({ theme }) => theme.polar};
    bottom: 0;
    left: 7px;
    border: 2px solid ${({ theme }) => theme.tertiaryPrimaryDarkest};
    border-bottom: none;
    padding: 0.25rem 0.5rem;
    z-index: 99;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: ${easing.theme};
`;

const StyledButton = styled(Button)`
    padding: 0;

    &:hover {
        ${Overlay} {
            width: 400px;
            height: 400px;
            right: -200px;
            top: -200px;
        }

        ${PostTextContainer} {
            height: 0;
            bottom: -2px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
`;

const PostListItem = ({ item }) => {
    const image = item.featuredImage
    const url = '/' + item.uri
    // console.log(item)
    return (
        <StyledButton isPrimaryButton={true} key={item.id} href={`${blogURI}${url}`}>
            <Overlay>
                <StyledIcon fill={({ theme }) => theme.primaryTertiary} icon="chevronright"/>
            </Overlay>

            <PostTextContainer>
                <Heading isH6={true}>{item.title}</Heading>
            </PostTextContainer>

            {image && ((
                <FluidImage image={image}/>
            ))}
        </StyledButton>
    )
}

export default PostListItem