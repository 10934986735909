import React, { Component } from 'react';
import styled from 'styled-components';
// Components
import SliderGlide from './SliderGlide';
import SliderItem from './SliderItem';
import "../layout.css"
import { device } from '../../global/theme';

const SliderContainer = styled.div`
  position: relative;
  width: 345px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  @media ${device.mobileL} {
    width: 690px;
  }
`;

class Slider extends Component {
    state = {
      
    };
    handleOffer = id => {
      console.log("handleOffer clicked, id: ", id);
    };
  
    render() {
      const carouselOptions = { type: "slide", perView: 1, startAt: 0, gap: 0,  };
  
      return (
        <SliderContainer>
          <SliderGlide options={carouselOptions}>
            {this.props.slider.map((item, index) => (
              <SliderItem
                key={index}
                item={item}
                handleOffer={this.handleOffer}
              />
            ))}
          </SliderGlide>
        </SliderContainer>
      );
    }
  }
export default Slider;