import React, { Fragment, Component } from 'react'
import Glide from '@glidejs/glide'
// Atoms
import { Icon } from '../../atoms/icon/Icon';
// Graphics
import Laptop from '../../global/graphics/Laptop';
// Theme
import "../layout.css"
import { Slider, SliderButtonContainer, SliderArrowLeft, SliderArrowRight } from './style';

export default class SliderGlide extends Component {
    state = { id: null };
  
    componentDidMount = () => {
      // Generated random id
      this.setState(
        { id: `glide-${Math.ceil(Math.random() * 100)}` },
        this.initializeGlider
      );
    };
  
    initializeGlider = () => {
      this.slider = new Glide(`#${this.state.id}`, this.props.options);
      this.slider.mount();
    };
  
    componentWillReceiveProps = newProps => {
      if (this.props.options.startAt !== newProps.options.startAt) {
        this.slider.go(`=${newProps.options.startAt}`);
      }
    };
  
    render = () => (
      // controls
      <Fragment>
        <Slider id={this.state.id}>
          <SliderButtonContainer data-glide-el="controls">
            <SliderArrowLeft data-glide-dir="<" title="previous">
              <Icon icon="chevronright" size="25px" style={{ transform: "rotate(180deg)" }} fill={({ theme }) => theme.tertiaryPrimary} />
            </SliderArrowLeft>
            <SliderArrowRight  data-glide-dir=">" title="next">
              <Icon icon="chevronright" size="25px" fill={({ theme }) => theme.tertiaryPrimary} />
            </SliderArrowRight>
          </SliderButtonContainer>
          {/* track  */}
          
          <div className="glide__track" data-glide-el="track" style={{ overflowX: "hidden" }}>
            <div className="glide__slides" style={{ display: "flex" }}>
              {this.props.children.map((slide, index) => {
                return React.cloneElement(slide, {
                  key: index,
                  className: `${slide.props.className} glide__slide`
                });
              })}
            </div>
          </div>
        </Slider>

        <div style={{position: 'absolute', width: "100%", height: '100%', zIndex: '-1'}}>
          <Laptop />
        </div>
      </Fragment>
    );
  }
  
  SliderGlide.defaultProps = {
    options: {}
  };