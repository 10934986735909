import React from 'react';
//import styled from 'styled-components'
// Layout Component
import PageListItem from './PageListItem';
import PostListItem from './PostListItem';
// Components
import Divider from '../../components/Divider/index';
import LefthandTextColumn from '../TextBlock/LefthandTextColumn';
// Global
import { Column, Container, Row, Wrapper, Grid } from '../../global/grid';
import { Section } from '../../atoms/section/Section';

import { col, position } from '../../global/theme';

const PageList = ({ pageLinks, title, subTitle, pageOrPost }) => {
    //console.log(pageLinks)
    return (
        <Section>
            <Wrapper>
                <Row innerStack justifyContent={position.center} paddedRow>
                    <Column xsWidth={col.twelve} mdWidth={col.six}>
                        <LefthandTextColumn 
                            title={title}
                            subTitle={subTitle}
                            align="center"
                        />
                    </Column>
                </Row>
                <Container>
                    <Grid>
                        {!pageOrPost && ((
                            pageLinks.map((item, index) => (
                                <PageListItem
                                    key={index}
                                    item={item}
                                />
                            ))
                        ))}
                        {pageOrPost && ((
                            pageLinks.map((item, index) => (
                                <PostListItem
                                    key={index}
                                    item={item}
                                />
                            ))
                        ))}
                    </Grid>
                </Container>
                <Divider/>
            </Wrapper>
        </Section>
    );
};

export default PageList;
