import styled from 'styled-components';
// Theme
import { device } from '../../global/theme';

export const Slider = styled.div`
  position: relative;
  width: 290px;
  height: 100%;
  margin-top: 10px;
  margin-left: -32.5px;
  user-select: none;

  @media ${device.mobileL} {
    width: 681px;
    margin-left: -1px;
    margin-top: 10.5px;
  }
`;

export const SliderButtonContainer = styled.div`
    position: absolute;
    width: 100%;
    margin: auto;
    padding: 0 0.5rem;
    left: 0;
    right: 0;
    bottom: -40%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;

    @media ${device.mobileL} {
      padding: 0 0.5rem;
      left: 0;
      bottom: 43%;
    }
`;

export const SliderArrowLeft = styled.button`
    left: -25px;
    position: absolute;
    background: transparent;
    border: none;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50%;

    @media ${device.mobileL} {
      left: -100px;
    }
`;

export const SliderArrowRight = styled.button`
    right: -25px;
    position: absolute;
    background: transparent;
    border: none;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50%;

    @media ${device.mobileL} {
      right: -100px;
    }
`;