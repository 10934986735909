import React, { Fragment } from 'react';
import styled from 'styled-components';
// Components
import Divider from '../../components/Divider/index';
import Slider from '../../components/Slider/index';
// Atoms
import { Section } from '../../atoms/section/Section';
// Theme
import {  Column, Container, Row, Wrapper } from '../../global/grid';
import { col, device, position } from '../../global/theme';
import LefthandTextColumn from '../TextBlock/LefthandTextColumn';
import AddButton from '../../components/AddButton';

const StyledContainer = styled(Container)`
    padding-bottom: 3rem;
    
    @media ${device.mobileL} {
        padding-bottom: 6rem;
    }
`;


const ImageSlider = ({ title, subTitle, slider, addButton, button }) => {
    console.log(ImageSlider);
    return (
        <Fragment>
            <Section>
                <Wrapper>
                    <Row innerStack justifyContent={position.center} paddedRow>
                        <Column xsWidth={col.twelve} mdWidth={col.six}>
                            <LefthandTextColumn 
                                title={title}
                                subTitle={subTitle}
                                align="center"
                            />
                        </Column>
                        <Column xsWidth={col.twelve} smWidth={col.ten} mdWidth={col.six}>
                            <StyledContainer>
                                <Slider
                                    slider={slider}
                                    title={title}
                                />
                            </StyledContainer>
                        </Column>
                        <Column flex justifyContent={position.center} xsWidth={col.twelve}>
                            <AddButton
                                addButton={addButton}
                                button={button}
                                isPrimaryButton={true}
                            />
                        </Column>
                    </Row>
                    <Divider/>
                </Wrapper>
            </Section>
        </Fragment>
    );
};

export default ImageSlider;
